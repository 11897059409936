import axios from "axios";
import CONSTANTS from "@/config";
import { v4 as uuidv4 } from "uuid";

const Axios = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
  headers: {
    deviceid: uuidv4(),
    "Content-Type": "application/json",
  },
});

Axios.interceptors.request.use((req) => {
  return {
    ...req,
    url: Boolean(CONSTANTS[req?.url]) ? CONSTANTS[req.url] : req.url,
  };
});

export default Axios;
