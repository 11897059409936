import * as React from "react";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import formatOptions from "@/features/jobSearch/hooks/formatOption";

function Desktop({ initialData, handleChange, router, handleOnFocus }) {
  return (
    <Stack direction="row" sx={{ width: "100%" }} columnGap={0.5} mt={2}>
      <Stack
        direction="row"
        sx={{
          maxWidth: "100%",
          width: "100%",
          "& .MuiInputBase-root": {
            borderRadius: "32px",
            height: 40,
            bgcolor: "white",
          },
          "& .MuiPaper-root": {
            boxShadow: "0px 3px 6px #00000029",
            border: "1px solid #D9D9DA",
            borderRadius: "8px",
          },
        }}
        columnGap={0.5}
      >
        <Select
          id="job-cat"
          variant="outlined"
          size="small"
          color="info"
          fullWidth
          displayEmpty
          MenuProps={{ className: "selectMenu" }}
          disabled={
            formatOptions(initialData?.jobCat?.dataList || []).length <= 0
          }
          onChange={(event) => {
            if (event.target.value !== "")
              handleChange("", event.target.value, "jobCat");
          }}
          value={
            formatOptions(initialData?.jobCat?.dataList || [])?.find(
              (data) => data?.isSelected == 1
            )?.id || ""
          }
          onFocus={handleOnFocus}
        >
          <MenuItem pr value="" className="bjjbjbjbj">
            Job Type
          </MenuItem>
          {formatOptions(initialData?.jobCat?.dataList || []).map(
            (item, index) => (
              <MenuItem key={index} value={item?.id} className="bjjbjbjbj">
                {item?.item}
              </MenuItem>
            )
          )}
        </Select>
        <Select
          id="insCat"
          variant="outlined"
          size="small"
          color="info"
          displayEmpty
          MenuProps={{ className: "selectMenu" }}
          fullWidth
          onChange={(event) => {
            if (event.target.value !== "")
              handleChange("", event.target.value, "insCat");
          }}
          disabled={
            formatOptions(initialData?.insCat?.dataList || []).length <= 0
          }
          value={
            formatOptions(initialData?.insCat?.dataList || [])?.find(
              (data) => data?.isSelected == 1
            )?.id || ""
          }
          onFocus={handleOnFocus}
        >
          <MenuItem value="">Institute Type</MenuItem>
          {formatOptions(initialData?.insCat?.dataList || []).map(
            (item, index) => (
              <MenuItem key={index} value={item?.id}>
                {item?.item}
              </MenuItem>
            )
          )}
        </Select>
        <Select
          id="subCat"
          variant="outlined"
          size="small"
          color="info"
          MenuProps={{ className: "selectMenu" }}
          displayEmpty
          fullWidth
          onChange={(event) => {
            if (event.target.value !== "")
              handleChange("", event.target.value, "subCat");
          }}
          disabled={
            formatOptions(initialData?.subCat?.dataList || []).length <= 0
          }
          value={
            formatOptions(initialData?.subCat?.dataList || [])?.find(
              (data) => data?.isSelected == 1
            )?.id || ""
          }
          onFocus={handleOnFocus}
        >
          <MenuItem value={""}>Subcategory</MenuItem>
          {formatOptions(initialData?.subCat?.dataList || []).map(
            (item, index) => (
              <MenuItem key={index} value={item?.id}>
                {item?.item}
              </MenuItem>
            )
          )}
        </Select>
        <Select
          id="subCat2"
          variant="outlined"
          size="small"
          color="info"
          MenuProps={{ className: "selectMenu" }}
          displayEmpty
          fullWidth
          onChange={(event) => {
            if (event.target.value !== "")
              handleChange("", event.target.value, "subCat2");
          }}
          disabled={
            formatOptions(initialData?.subCat2?.dataList || []).length <= 0
          }
          value={
            formatOptions(initialData?.subCat2?.dataList || [])?.find(
              (data) => data?.isSelected == 1
            )?.id || ""
          }
          onFocus={handleOnFocus}
        >
          <MenuItem value={""}>Level/Exam Type</MenuItem>
          {formatOptions(initialData?.subCat2?.dataList || []).map(
            (item, index) => (
              <MenuItem key={index} value={item?.id}>
                {item?.item}
              </MenuItem>
            )
          )}
        </Select>
        <Select
          id="role"
          variant="outlined"
          size="small"
          color="info"
          MenuProps={{ className: "selectMenu" }}
          displayEmpty
          fullWidth
          onChange={(event) => {
            if (event.target.value !== "")
              handleChange("", event.target.value, "role");
          }}
          disabled={
            formatOptions(initialData?.role?.dataList || []).length <= 0
          }
          value={
            formatOptions(initialData?.role?.dataList || [])?.find(
              (data) => data?.isSelected == 1
            )?.id || ""
          }
          onFocus={handleOnFocus}
        >
          <MenuItem value={""}>Role</MenuItem>
          {formatOptions(initialData?.role?.dataList || []).map(
            (item, index) => (
              <MenuItem key={index} value={item?.id}>
                {item?.item}
              </MenuItem>
            )
          )}
        </Select>
        <Select
          id="subject"
          variant="outlined"
          size="small"
          color="info"
          MenuProps={{ className: "selectMenu" }}
          displayEmpty
          fullWidth
          onChange={(event) => {
            if (event.target.value !== "")
              handleChange("", event.target.value, "subject");
          }}
          disabled={
            formatOptions(initialData?.subject?.dataList || []).length <= 0
          }
          value={
            formatOptions(initialData?.subject?.dataList || [])?.find(
              (data) => data?.isSelected == 1
            )?.id || ""
          }
          onFocus={handleOnFocus}
        >
          <MenuItem value={""}>Subject</MenuItem>
          {formatOptions(initialData?.subject?.dataList || []).map(
            (item, index) => (
              <MenuItem key={index} value={item?.id}>
                {item?.item}
              </MenuItem>
            )
          )}
        </Select>
        <Select
          id="nonAcademicRole"
          variant="outlined"
          size="small"
          MenuProps={{ className: "selectMenu" }}
          color="info"
          displayEmpty
          fullWidth
          onChange={(event) => {
            if (event.target.value !== "")
              handleChange("", event.target.value, "nonAcademicRole");
          }}
          disabled={
            formatOptions(initialData?.nonAcademicRole?.dataList || [])
              .length <= 0
          }
          value={
            formatOptions(initialData?.nonAcademicRole?.dataList || [])?.find(
              (data) => data?.isSelected == 1
            )?.id || ""
          }
          onFocus={handleOnFocus}
        >
          <MenuItem value={""}>Non Academic Type</MenuItem>
          {formatOptions(initialData?.nonAcademicRole?.dataList || []).map(
            (item, index) => (
              <MenuItem key={index} value={item?.id}>
                {item?.item}
              </MenuItem>
            )
          )}
        </Select>
      </Stack>
      <Button
        color="error"
        variant="text"
        disabled={!router?.query?.sid}
        onClick={() => router.push(router?.query?.keyword)}
      >
        Reset
      </Button>
    </Stack>
  );
}

export default React.memo(Desktop);
