const formatOptions = (options) => {
  return options?.map((data) => {
    if (Boolean(data.item)) {
      const [name, id] = data.item.split("#");
      return { ...data, item: `${name} (${data.count})`, id: id };
    } else {
      return { ...data, item: `(${data.count})` };
    }
  });
};

export default formatOptions;
