export const format = (str) => {
  return str
    ?.replace(/[^\w\s,()!@#\$&+\-/*]/gi, "")
    .replace(/&/g, "-")
    .replace(/\//g, "-")
    .replace(/[,\s]+/g, "-")
    .toString()
    .toLowerCase();
};

export const SearchEngine = (url, location) => {
  if (
    url !== "/" &&
    url?.includes("jobs near") &&
    !url?.includes("jobs near me")
  ) {
    return {
      status: false,
      url: null,
    };
  } else if (
    url !== "/" &&
    (url?.includes("job near") || url?.includes("jobs near"))
  ) {
    if (
      Boolean(
        location &&
          (url?.includes("jobs near me") || url?.includes("job near me"))
      )
    ) {
      if (url?.includes("job near me")) {
        return {
          status: true,
          url: format(
            `${url.replace("job", "jobs")}`.replace(
              "near me",
              "near " + location
            )
          ),
        };
      } else {
        return {
          status: true,
          url: format(`${url.replace("near me", "near " + location)}`),
        };
      }
    } else {
      return {
        status: true,
        url: format(url.replace("job", "jobs")),
      };
    }
  } else if (url !== "/" && url?.includes("job in")) {
    return {
      status: true,
      url: format(url.replace("job", "jobs")),
    };
  } else if (url !== "/" && !url?.includes("jobs in")) {
    if (Boolean(location)) {
      if (url?.includes("job") && !url?.includes("jobs")) {
        return {
          status: true,
          url: format(`${url.replace("job", "jobs")} in ${location}`),
        };
      } else if (!url?.includes("job") || !url?.includes("jobs")) {
        return {
          status: true,
          url: format(`${url} jobs in ${location}`),
        };
      } else if (url?.includes("jobs") && !url?.includes("jobs in")) {
        if (url.lastIndexOf("jobs") + 4 === url.length) {
          return {
            status: true,
            url: format(`${url} in ${location}`),
          };
        } else {
          return {
            status: true,
            url: format(`${url.replace("jobs", "jobs in")}`),
          };
        }
      } else {
        return {
          status: false,
          url: null,
        };
      }
    } else {
      if (!url?.includes("job") && !url?.includes("jobs")) {
        return {
          status: true,
          url: format(`${url} jobs`),
        };
      } else if (url?.includes("job") && !url?.includes("jobs")) {
        return {
          status: true,
          url: format(url.replace("job", "jobs")),
        };
      } else {
        return {
          status: false,
          url: null,
        };
      }
    }
  } else {
    return {
      status: false,
      url: null,
    };
  }
};
