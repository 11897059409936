/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import dynamic from "next/dynamic";
import Desktop from "./desktop";
import Mobile from "./mobile";

const FilterPop = dynamic(
  () => import("@/features/jobSearch/components/Filters/FilterPop"),
  { ssr: false }
);

function VerticalFilters({ handleChange, filterData, device, handleOnFocus }) {
  const [showMorePopup, setShowMorePopup] = React.useState({ show: false });

  const formatMinMaxRange = (range) => {
    if (Number(range?.split("#")[0]) === Number(range?.split("#")[1]))
      return {
        start: 0,
        end: Number(range?.split("#")[1]) * 2,
      };
    else
      return {
        start: Number(range?.split("#")[0]),
        end: Number(range?.split("#")[1]),
      };
  };

  const Salarycount = (val, curr) => {
    let value = Math.abs(val);
    if (value >= 100000) {
      value = (value / 100000).toFixed(2);
    } else if (value < 100000 && value >= 1000) {
      value = (value / 100000).toFixed(2);
    } else if (value < 1000) {
      value = value;
      return `${value}`;
    }
    return `${value} ${curr || "LPA"}`;
  };

  const midValue = React.useMemo(
    () =>
      (formatMinMaxRange(filterData?.sal?.availableRange).start +
        formatMinMaxRange(filterData?.sal?.availableRange).end) /
      2,
    [filterData?.sal]
  );

  const [value, setValue] = React.useState(
    filterData?.sal?.selectedValue || filterData?.sal?.current || midValue
  );

  React.useEffect(() => {
    setValue(
      filterData?.sal?.selectedValue || filterData?.sal?.current || midValue
    );
  }, [filterData?.sal]);

  const FilterDialog = React.useMemo(
    () =>
      showMorePopup?.show ? (
        <FilterPop
          open={showMorePopup?.show}
          onClose={() => setShowMorePopup({ show: false })}
          handleSelect={(value) =>
            handleChange("", value, showMorePopup?.title)
          }
          device={device}
          headertitle={showMorePopup?.header}
          type={"radio"}
          show={showMorePopup?.show}
          list={showMorePopup?.data}
        />
      ) : (
        ""
      ),
    [showMorePopup, device]
  );

  return (
    <>
      <Box
        sx={{
          position: "sticky",
          top: "80px",
          maxHeight: "90vh",
          overflowY: "overlay",
          overflowX: "hidden",
          "@media(max-height:780px)": {
            maxHeight: "85vh",
          },
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "&:hover": {
            "&::-webkit-scrollbar": {
              display: "unset",
            },
          },
        }}
      >
        {device === "desktop" ? (
          <>
            <Typography
              variant="subtitle1"
              sx={{
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                fontWeight: 600,
              }}
              pb={1}
            >
              <i
                className="icon-sliders"
                style={{ fontWeight: 600, marginRight: "4px" }}
              />
              Filters
            </Typography>
            <Divider light />
            <Typography color="primary" py={1}>
              Applied Filters
            </Typography>
            <Divider light sx={{ mb: 2 }} />
          </>
        ) : (
          ""
        )}
        <Stack
          direction={{ xs: "row", md: "column" }}
          alignItems="center"
          sx={{
            bgcolor: {
              xs: "transparent",
              md: "white",
            },
            p: { xs: "0px 0px 12px", md: 0 },
            boxShadow: { xs: "0 0 0 100vmax #fafafa", md: "none" },
          }}
        >
          {device === "desktop" ? (
            <Desktop
              location={filterData?.location}
              exp={filterData?.exp}
              sal={filterData?.sal}
              npd={filterData?.npd}
              empType={filterData?.empType}
              edu={filterData?.edu}
              value={value}
              setValue={setValue}
              Salarycount={Salarycount}
              setShowMorePopup={setShowMorePopup}
              handleChange={handleChange}
              formatMinMaxRange={formatMinMaxRange}
              handleOnFocus={handleOnFocus}
            />
          ) : (
            <Mobile
              location={filterData?.location}
              exp={filterData?.exp}
              sal={filterData?.sal}
              npd={filterData?.npd}
              empType={filterData?.empType}
              edu={filterData?.edu}
              value={value}
              setValue={setValue}
              Salarycount={Salarycount}
              setShowMorePopup={setShowMorePopup}
              handleChange={handleChange}
              formatMinMaxRange={formatMinMaxRange}
              handleOnFocus={handleOnFocus}
            />
          )}
        </Stack>
      </Box>
      {FilterDialog}
    </>
  );
}

export default React.memo(VerticalFilters);
