/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// MUI components
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

//MUI Icons
import { SearchEngine } from "@/features/home/hooks";
// Next.js hooks and components
import dynamic from "next/dynamic";
import { useRouter } from "next/router";

import checkBot from "@/hooks/botCheck";
import Axios from "@/lib/Axios";

import VerticalFilters from "@/features/jobSearch/components/Filters/VerticalFilters";
import HorizontalFilters from "@/features/jobSearch/components/Filters/HorizontalFilters";
import formatKeyword from "@/features/jobSearch/hooks/formatKeyword";

import { setSignUpOpen } from "@/store/slices/SignupSlice";
import { updateAuthTabStatus } from "@/store/slices/commonSlice";
import { useDispatch, useSelector } from "react-redux";

const Shimmer = dynamic(() => import("@/features/jobSearch/styles/shimmer"), {
  ssr: false,
});

const TooManyRequests = dynamic(
  async () => import(/* webpackChunkName: "404" */ '@/features/429/components'),
  { ssr: false }
);

const SomethingWentWrong = dynamic(
  async () => import(/* webpackChunkName: "404" */ '@/features/400/components'),
  { ssr: false }
);


const VerticalLoder = dynamic(
  () => import("@/features/jobSearch/components/Loders/verticalLoader"),
  {
    ssr: true,
  }
);
const HorizontalLoader = dynamic(
  () => import("@/features/jobSearch/components/Loders/horizontalLoader"),
  {
    ssr: true,
  }
);

const JobsList = dynamic(
  () => import("@/features/jobSearch/components/JobsList"),
  {
    ssr: true,
  }
);
const SeoHead = dynamic(
  () => import("@/features/jobSearch/components/SeoHead"),
  {
    ssr: true,
  }
);
const Faq = dynamic(() => import("@/features/jobSearch/components/faq"), {
  ssr: true,
});
const SeoBreadCrumb = dynamic(
  () => import("@/features/jobSearch/components/SeoBreadCrumb"),
  {
    ssr: true,
  }
);
const FooterSearch = dynamic(
  () => import("@/features/jobSearch/components/SearchFooter"),
  {
    ssr: true,
  }
);

function JOBSearch({
  queryString,
  searchData,
  suggestedData,
  seoContent,
  device,
  userAgent,
  metaData,
  searchApiStatusCode
}) {
  const router = useRouter();
  const dispatch = useDispatch();

  const common = useSelector((state) => state?.commonReducer);
  const login = useSelector((state) => state?.login);
  const register = useSelector((state) => state?.signUp);

  const [check, setCheck] = React.useState(false);
  const [filterFocus, setFilterFocus] = useState(false);
  const [filterApiRespStatusCode, setFilterApiRespStatusCode ] = useState(140);

  React.useEffect(() => {
    setTimeout(() => {
      setCheck(true);
    }, 14000);
  }, []);

  React.useEffect(() => {
    if (
      common.open === false &&
      login.open === false &&
      register.open === false &&
      router.pathname === "/[keyword]"
    ) {
      if (check === true && filterFocus === false) {
        dispatch(updateAuthTabStatus("Seeker"));
        dispatch(setSignUpOpen(check));
      }
    }
  }, [check, filterFocus]);

  // *** Handle filters onFocus or onClick *** //
  const handleOnFocus = () => {
    setFilterFocus(true);
  };

  const [searchValue, setSearchValue] = useState(
    router?.query?.keyword?.replaceAll("-", " ")
  );
  const [loading, setLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(true);
  const [filter, setFilter] = useState(null);

  const getFilters = async () => {
    try {
      const data = await Axios.get(
        `${process.env.NEXT_PUBLIC_OPEN_SEARCH_URL}/get-filters`,
        {
          params:
            {
              keyword: router.query.keyword.replaceAll("-", " "),
              ...queryString,
            } || "",
          headers: { token: `${process.env.NEXT_PUBLIC_OPEN_SEARCH_TOKEN}` },
        }
      ).then((res) => {
        setFilterApiRespStatusCode(res.status)
       return res.data
      });
      if (data?.status === false) {
        router.push("/404");
        setFilterLoading(false);
      } else {
        setFilter(data?.filters);
        setFilterLoading(false);
      }
    } catch (error) {
      setFilterLoading(false);
      setFilterApiRespStatusCode(err.response.status)
      router.push("/404");
    }
  };

  React.useEffect(() => {
    setSearchValue(router?.query?.keyword.replaceAll("-", " "));

    // Bot checking for filter api
    if (checkBot(userAgent?.toString())) {
      setFilterLoading(false)
    } else {
      getFilters();
    }

    setLoading(false);
  }, [router?.query]);

  const handleChange = async (e, value, key) => {
    if (router.query[key]) delete router.query[key];
    setLoading(true);
    const query = {
      ...router.query,
      pageNo: 1,
      pageItems: 20,
      sid: searchData?.sid,
      [key]: value?.id || value,
    };
    router.push({
      pathname: router.pathname,
      query: query,
    });
  };

  const handleNewPageChnage = async (title) => {
    setLoading(true);
    if (
      title !== router.query.keyword.replaceAll("-", " ") &&
      Boolean(title?.trim() !== "")
    ) {
      const { status, url } = SearchEngine(
        title
          ?.replace("/", "")
          ?.replaceAll(/[^a-zA-Z0-9 ]/g, " ")
          ?.toLowerCase(),
        localStorage.getItem("currentLocation")
      );
      if (status) {
        router.push("/" + url);
      } else {
        router.push("/" + formatKeyword(title));
      }
    }
    setLoading(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && e.target.value != "") {
      setLoading(true);
      router.push(formatKeyword(e.target.value));
    }
  };

  const BASE_URL1 = process?.env?.NEXT_PUBLIC_CANONICAL_DOMAIN + "/";

  /* ALLOW LOCATION GOOGLE -- START */
  /*
    const getCityName = async (lat, lng) => {
      const GOOGLE_API_KEY = "AIzaSyBAcSulRHyCk2yYHXzAlPZHBntJrNsAZi8";
      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_API_KEY}`;
      const response = await axios.get(url);
      const city = response?.data?.results[0]?.address_components?.find(
        (component) => component?.types?.includes("locality")
      )?.long_name;
      return city;
    };

    React.useEffect(() => {
      if (
        !checkBot(navigator.userAgent.toString()) ||
        !localStorage.getItem("currentLocation")
      ) {
        setTimeout(async () => {
          let isLocationAvailable = false;
          for (let i = 0; i < suggestedData?.length; i++) {
            if (
              router?.query?.keyword
                .toLowerCase()
                .includes(suggestedData[i]?.cityName?.toLowerCase())
            ) {
              isLocationAvailable = true;
              break;
            }
          }

          if (!isLocationAvailable) {
            const permissionStatus = await navigator?.permissions?.query({
              name: "geolocation",
            });
            if (permissionStatus?.state === "prompt") {
              // call your function here
              if (navigator?.geolocation) {
                navigator?.geolocation.getCurrentPosition(
                  (position) => {
                    getCityName(
                      position?.coords?.latitude,
                      position?.coords?.longitude
                    ).then((city) => {
                      localStorage.setItem("currentLocation", city);
                      handleNewPageChnage(router?.asPath);
                    });
                  },
                  (error) => {
                    console.error(error);
                  }
                );
              }
            } else if (permissionStatus.state === "denied") {
              localStorage.removeItem("currentLocation");
            }
          }
        }, 3000);
      }
    }, []);
    /* ALLOW LOCATION GOOGLE -- END */

  const STRUCUTRE = React.useMemo(
    () => (
      <>
      <Container maxWidth="xl">
        <Grid container columnSpacing={{ xs: 0, md: 2 }} mt={4}>
          <Grid item xs={12} md={3}>
            {filterLoading === false ? (
              <VerticalFilters
                handleChange={handleChange}
                filterData={filter}
                device={device}
                handleOnFocus={handleOnFocus}
              />
            ) : (
              <VerticalLoder device={device} />
            )}
          </Grid>
          <Grid item xs={12} md={9}>
            <JobsList
              handleChange={handleChange}
              jobData={{
                searchResultData: searchData?.jobList || [],
                pageData: searchData?.pageData,
                sort: searchData?.sort || "relevance",
                sid: searchData?.sid,
              }}
              queryString={queryString}
              device={device}
              router={router}
              handleOnFocus={handleOnFocus}
              firstHeader={metaData?.firstHeader}
            />
          </Grid>
        </Grid>
        <FooterSearch
          data={suggestedData}
          router={router}
          device={device}
          searchData={searchData}
          handleNewPageChnage={handleNewPageChnage}
          secondHeader={metaData?.secondHeader}
        />
        <Faq seoContent={seoContent || []} />
      </Container>
      <Container maxWidth="lg" sx={{mb:2}}>
      {filterLoading === false ? (
        <SeoBreadCrumb
          breadcrumb={searchData?.breadcrumb}
          router={router}
          device={device}
        />
      ) : (
        ""
      )}
      </Container>
      </>
    ),
    [router, queryString, searchData, filter, suggestedData, filterLoading]
  );

  return (

    (filterApiRespStatusCode === 200 && searchApiStatusCode === 200) ||
    (filterApiRespStatusCode === 140 && searchApiStatusCode === 200)
    ?
    <>
    <SeoHead
      title={metaData?.metatitle}
      description={metaData?.metaDescription}
      canonical={`${BASE_URL1}${router.query.keyword}`}
      breadcrumb={searchData?.breadcrumb}
      path={router.query.keyword}
      metatitle={metaData?.metatitle}
      pageData={searchData?.pageData}
      botCheck={!checkBot(userAgent.toString())}
    />

    {Boolean(
      loading || !queryString || !searchData || !suggestedData || !device
    ) ? (
      <Shimmer />
    ) : (
      <>
        <Box sx={{ bgcolor: "#e9f3ff", p: { xs: "16px 0", sm: "32px 0" } }}>
          <Container maxWidth="xl">
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              sx={{
                "& .MuiInputBase-root": {
                  bgcolor: "white",
                  height: { xs: "42px", md: "52px" },
                  pr: "8px",
                },
              }}
            >
              <TextField
                variant="outlined"
                autoComplete="off"
                fullWidth
                focused={false}
                value={searchValue}
                onKeyDown={(e) => handleKeyPress(e)}
                onChange={(e) => setSearchValue(e.target.value)}
                onFocus={handleOnFocus}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <i
                        className="icon-search"
                        style={{ fontSize: "18px" }}
                      />
                    </InputAdornment>
                  ),
                }}
                sx={{ mr: { xs: 1, md: 2 } }}
              />
              <Button
                color="primary"
                variant="contained"
                sx={{
                  borderRadius: { md: "10px" },
                  width: { xs: "100px", md: "120px" },
                  fontSize: { xs: "16px", md: "18px" },
                }}
                disableElevation
                disabled={!searchValue}
                onClick={() => handleNewPageChnage(searchValue)}
              >
                Search
              </Button>
            </Box>
            {filterLoading === false ? (
              <HorizontalFilters
                handleChange={handleChange}
                initialData={filter}
                router={router}
                device={device}
                handleOnFocus={handleOnFocus}
              />
            ) : (
              <HorizontalLoader />
            )}
          </Container>
        </Box>
        {STRUCUTRE}
      </>
    )}
    </>
    : filterApiRespStatusCode === 429 || searchApiStatusCode === 429
    ? <TooManyRequests/>
    : <SomethingWentWrong/>
  );
}

export default React.memo(JOBSearch);
