import * as React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import formatOptions from "@/features/jobSearch/hooks/formatOption";


function Mobile({ initialData, handleChange, router, handleOnFocus }) {
  const [open, setOpen] = React.useState(false);
  const [check, setCheck] = React.useState("");

  const handleChangeFilter = (event) => {
    setOpen(true);
    setCheck(event);
    handleOnFocus()
  };
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          maxWidth: "100%",
        }}
      >
        <Stack direction="row" sx={{ width: "100%" }} columnGap={0.5} mt={2}>
          <Stack
            direction="row"
            sx={{
              overflowX: "scroll",
              maxWidth: "100%",
              width: "100%",
              "::-webkit-scrollbar": {
                display: "none",
              },
              "& .MuiButtonBase-root": {
                width: "100%",
                bgcolor: "white",
                borderRadius: "120px",
                whiteSpace: "nowrap",
                padding: "0 12px",
                border: "1px solid #DEDEDF",
                minWidth: "auto",
              },
            }}
            columnGap={0.5}
          >
            <Button
              disableRipple
              variant="outlined"
              endIcon={
                <i className="icon-arrow-down" style={{ fontSize: 12 }} />
              }
              color="inherit"
              size="small"
              onClick={() => handleChangeFilter("jobtype")}
              disabled={
                formatOptions(initialData?.jobCat?.dataList || []).length <= 0
              }
            >
              {formatOptions(initialData?.jobCat?.dataList || [])
                ?.find((data) => data?.isSelected == 1)
                ?.item?.substring(0, 15) || "Job Type"}
            </Button>
            <Button
              variant="outlined"
              endIcon={
                <i className="icon-arrow-down" style={{ fontSize: 12 }} />
              }
              onClick={() => handleChangeFilter("institutetype")}
              color="inherit"
              size="small"
              disabled={
                formatOptions(initialData?.insCat?.dataList || []).length <= 0
              }
            >
              {formatOptions(initialData?.insCat?.dataList || [])
                ?.find((data) => data?.isSelected == 1)
                ?.item?.substring(0, 15) || "Institute Type"}
            </Button>
            <Button
              variant="outlined"
              endIcon={
                <i className="icon-arrow-down" style={{ fontSize: 12 }} />
              }
              onClick={() => handleChangeFilter("Subcategory")}
              color="inherit"
              size="small"
              disabled={
                formatOptions(initialData?.subCat?.dataList || []).length <= 0
              }
            >
              {formatOptions(initialData?.subCat?.dataList || [])
                ?.find((data) => data?.isSelected == 1)
                ?.item?.substring(0, 15) || "Subcategory"}
            </Button>
            <Button
              onClick={() => handleChangeFilter("Level/Exam")}
              variant="outlined"
              endIcon={
                <i className="icon-arrow-down" style={{ fontSize: 12 }} />
              }
              color="inherit"
              size="small"
              disabled={
                formatOptions(initialData?.subCat2?.dataList || []).length <= 0
              }
            >
              {formatOptions(initialData?.subCat2?.dataList || [])
                ?.find((data) => data?.isSelected == 1)
                ?.item?.substring(0, 15) || "Level/Exam Type"}
            </Button>
            <Button
              onClick={() => handleChangeFilter("Role")}
              variant="outlined"
              endIcon={
                <i className="icon-arrow-down" style={{ fontSize: 12 }} />
              }
              color="inherit"
              size="small"
              disabled={
                formatOptions(initialData?.role?.dataList || []).length <= 0
              }
            >
              {formatOptions(initialData?.role?.dataList || [])
                ?.find((data) => data?.isSelected == 1)
                ?.item?.substring(0, 15) || "Role"}
            </Button>
            <Button
              onClick={() => handleChangeFilter("Subject")}
              variant="outlined"
              endIcon={
                <i className="icon-arrow-down" style={{ fontSize: 12 }} />
              }
              color="inherit"
              size="small"
              disabled={
                formatOptions(initialData?.subject?.dataList || []).length <= 0
              }
            >
              {formatOptions(initialData?.subject?.dataList || [])
                ?.find((data) => data?.isSelected == 1)
                ?.item?.substring(0, 15) || "Subject"}
            </Button>
            <Button
              onClick={() => handleChangeFilter("nonacademic")}
              variant="outlined"
              endIcon={
                <i className="icon-arrow-down" style={{ fontSize: 12 }} />
              }
              color="inherit"
              size="small"
              sx={{
                minWidth: 150,
                p: 0,
                bgcolor: "white",
                borderRadius: 30,
              }}
              disabled={
                formatOptions(initialData?.nonAcademicRole?.dataList || [])
                  .length <= 0
              }
            >
              {formatOptions(initialData?.nonAcademicRole?.dataList || [])
                ?.find((data) => data?.isSelected == 1)
                ?.item?.substring(0, 15) || "Non Academic Type"}
            </Button>
          </Stack>
          <Button
            color="error"
            variant="text"
            disabled={!router?.query?.sid}
            onClick={() => router.push(router?.query?.keyword)}
          >
            Reset
          </Button>
        </Stack>
        {open ? (
          <SwipeableDrawer
            anchor="bottom"
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            disableSwipeToOpen={false}
            PaperProps={{
              sx: {
                borderTopLeftRadius: 20,
                borderTopRightRadius: 20,
                p: 2,
                "& .MuiMenuItem-root": {
                  fontSize: "14px",
                  minHeight: "40px",
                  pt: "0px",
                  pb: "0px",
                  pl: "0px",
                  pr: "0px",
                },
              },
            }}
            swipeAreaWidth={50}
            transitionDuration={10}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <Stack sx={{ maxHeight: "50vh", height: "fit-content" }}>
              {check === "jobtype" ? (
                <>
                  {formatOptions(initialData?.jobCat?.dataList || []).map(
                    (item, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => handleChange("", item?.id, "jobCat")}
                      >
                        {item?.item}
                      </MenuItem>
                    )
                  )}
                </>
              ) : (
                ""
              )}
              {check === "institutetype" ? (
                <>
                  {formatOptions(initialData?.insCat?.dataList || []).map(
                    (item, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => handleChange("", item?.id, "insCat")}
                      >
                        {item?.item}
                      </MenuItem>
                    )
                  )}
                </>
              ) : (
                ""
              )}
              {check === "Subcategory" ? (
                <>
                  {formatOptions(initialData?.subCat?.dataList || []).map(
                    (item, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => handleChange("", item?.id, "subCat")}
                      >
                        {item?.item}
                      </MenuItem>
                    )
                  )}
                </>
              ) : (
                ""
              )}
              {check === "Level/Exam" ? (
                <>
                  {formatOptions(initialData?.subCat2?.dataList || []).map(
                    (item, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => handleChange("", item?.id, "subCat2")}
                      >
                        {item?.item}
                      </MenuItem>
                    )
                  )}
                </>
              ) : (
                ""
              )}
              {check === "Role" ? (
                <>
                  {formatOptions(initialData?.role?.dataList || []).map(
                    (item, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => handleChange("", item?.id, "role")}
                      >
                        {item?.item}
                      </MenuItem>
                    )
                  )}
                </>
              ) : (
                ""
              )}
              {check === "Subject" ? (
                <>
                  {formatOptions(initialData?.subject?.dataList || []).map(
                    (item, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => handleChange("", item?.id, "subject")}
                      >
                        {item?.item}
                      </MenuItem>
                    )
                  )}
                </>
              ) : (
                ""
              )}
              {check === "nonAcademicRole" ? (
                <>
                  {formatOptions(
                    initialData?.nonAcademicRole?.dataList || []
                  ).map((item, index) => (
                    <MenuItem
                      key={index}
                      onClick={() =>
                        handleChange("", item?.id, "nonAcademicRole")
                      }
                    >
                      {item?.item}
                    </MenuItem>
                  ))}
                </>
              ) : (
                ""
              )}
            </Stack>
          </SwipeableDrawer>
        ) : (
          ""
        )}
      </Stack>
    </>
  );
}

export default React.memo(Mobile);
