function checkBot(userAgent) {
  const botKeywords = new Set([
    "bot",
    "spider",
    "crawl",
    "slurp",
    "search",
    "agent",
    "http",
    "wget",
    "googlebot",
    "bingbot",
    "yandexbot",
    "duckduckbot",
    "ChatGPT",
    "chatbot",
    "facebookexternalhit",
    "facebookcatalog",
    "Jooblebot",
    "meta-externalagent",
    // Add more bot user agents as necessary
  ]);

  // Check if the user agent contains any bot-related keywords or specific bot user agent strings
  const lowerUserAgent = userAgent.toLowerCase();
  for (const keyword of botKeywords) {
    if (lowerUserAgent.includes(keyword)) {
      return true;
    }
  }

  return false;
}

export default checkBot;