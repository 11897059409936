import Desktop from "./desktop";
import Mobile from "./mobile";

function HorizontalFilters({ handleChange, initialData, router, device, handleOnFocus }) {
  return (
    <>
      {device === "desktop" ? (
        <Desktop
          initialData={initialData}
          handleChange={handleChange}
          router={router}
          handleOnFocus={handleOnFocus}
        />
      ) : (
        <Mobile
          initialData={initialData}
          handleChange={handleChange}
          router={router}
          handleOnFocus={handleOnFocus}
        />
      )}
    </>
  );
}

export default HorizontalFilters;
