import * as React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Slider from "@mui/material/Slider";
import dynamic from "next/dynamic";

const RadioBtnList = dynamic(() => import("../RadioBtnListComponent"), {
  ssr: true,
});
const RoundBtnList = dynamic(() => import("../RoundBtnListComponent"), {
  ssr: true,
});

const formatRange = (range) => {
  return {
    start: Number(range?.split("#")[0]),
    end: Number(range?.split("#")[1]),
  };
};

function Mobile({
  location,
  setShowMorePopup,
  handleChange,
  exp,
  sal,
  npd,
  edu,
  empType,
  Salarycount,
  value,
  formatMinMaxRange,
  setValue,
  handleOnFocus
}) {
  const [open, setOpen] = React.useState(false);
  const [check, setCheck] = React.useState("");

  const handleChangeFilter = (event) => {
    setOpen(true);
    setCheck(event);
  };
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        maxWidth: "100%",
        bgcolor: (theme) => theme.palette.primary[900],
        py: 2,
      }}
    >
      <i
        className="icon-sliders"
        style={{
          fontWeight: "600",
          marginRight: "4px",
          fontSize: "20px",
        }}
      />
      <Stack
        direction="row"
        sx={{
          overflowX: "scroll",
          maxWidth: "100%",
          "::-webkit-scrollbar": {
            display: "none",
          },
          "& .MuiButtonBase-root": {
            width: "100%",
            bgcolor: "white",
            borderRadius: "120px",
            whiteSpace: "nowrap",
            padding: "4px 12px",
            border: "1px solid #DEDEDF",
            minWidth: "auto",
          },
        }}
        columnGap={0.5}
      >
        <Button
          color="inherit"
          onClick={() => { handleChangeFilter("location"); handleOnFocus() }}
          size="small"
          variant="outlined"
          endIcon={<i className="icon-arrow-down" style={{ fontSize: 12 }} />}
        >
          Location
        </Button>
        <Button
          onClick={() => { handleChangeFilter("exp"); handleOnFocus() }}
          color="inherit"
          size="small"
          variant="outlined"
          endIcon={<i className="icon-arrow-down" style={{ fontSize: 12 }} />}
        >
          Experience
        </Button>
        <Button
          onClick={() => { handleChangeFilter("salary"); handleOnFocus() }}
          color="inherit"
          size="small"
          variant="outlined"
          endIcon={<i className="icon-arrow-down" style={{ fontSize: 12 }} />}
        >
          Salary
        </Button>
        <Button
          onClick={() => { handleChangeFilter("npd"); handleOnFocus() }}
          color="inherit"
          size="small"
          variant="outlined"
          endIcon={<i className="icon-arrow-down" style={{ fontSize: 12 }} />}
        >
          Notice
        </Button>
        <Button
          onClick={() => { handleChangeFilter("empType"); handleOnFocus() }}
          color="inherit"
          size="small"
          variant="outlined"
          endIcon={<i className="icon-arrow-down" style={{ fontSize: 12 }} />}
        >
          Employment
        </Button>
        <Button
          onClick={() => { handleChangeFilter("edu"); handleOnFocus() }}
          color="inherit"
          size="small"
          variant="outlined"
          endIcon={<i className="icon-arrow-down" style={{ fontSize: 12 }} />}
        >
          Education Required
        </Button>
      </Stack>
      {open ? (
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          disableSwipeToOpen={false}
          PaperProps={{
            sx: {
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
              p: 2,
            },
          }}
          swipeAreaWidth={50}
          transitionDuration={10}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Stack sx={{ maxHeight: "50vh", height: "fit-content" }} rowGap={0.5}>
            {check === "location" ? (
              <>
                <Typography
                  component="h3"
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                    mb: 2,
                    textAlign: "center",
                  }}
                >
                  Location
                </Typography>
                {location?.dataList?.length > 0 && (
                  <RadioBtnList
                    data={location}
                    handleChange={(e, value) => {
                      handleChange(e, value, "location");
                      handleOnFocus()
                    }}
                    setShowMorePopup={setShowMorePopup}
                    name="location"
                    ShowMoreHeader={"Location"}
                  />
                )}
              </>
            ) : (
              ""
            )}
            {check === "exp" ? (
              <>
                <Typography
                  component="h3"
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                    mb: 2,
                    textAlign: "center",
                  }}
                >
                  Minimum Experience Required ({exp?.unit})
                </Typography>
                {exp?.suggestedRange && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    columnGap={0.2}
                    mb={2}
                  >
                    <Typography variant="caption">Suggested Range: </Typography>
                    <Chip
                      label={
                        formatRange(exp?.suggestedRange).start +
                        "Y" +
                        " - " +
                        formatRange(exp?.suggestedRange).end +
                        "Y"
                      }
                      variant="outlined"
                      color="primary"
                      clickable={false}
                    />
                  </Stack>
                )}
                {exp?.dataList && (
                  <RoundBtnList
                    data={exp?.dataList?.toString()?.split(",")}
                    selectedValue={exp?.selectedValue}
                    handleChange={(e, value) => handleChange(e, value, "exp")}
                  />
                )}{" "}
              </>
            ) : (
              ""
            )}
            {check === "salary" ? (
              <>
                <Typography
                  component="h3"
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                    mb: 2,
                    textAlign: "center",
                  }}
                >
                  Minimum Acceptable Salary ({sal?.unit})
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    display: { xs: "none", sm: "block" },
                    py: "4px",
                    color: (theme) => theme.palette.dark[900],
                  }}
                >
                  Drag the slider to select desired value
                </Typography>
                {sal?.availableRange && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    columnGap={0.5}
                    mb={0.5}
                  >
                    <Typography variant="caption">Available : </Typography>
                    <Chip
                      label={
                        Salarycount(
                          formatRange(sal?.availableRange).start,
                          "L"
                        ) +
                        " - " +
                        Salarycount(formatRange(sal?.availableRange).end, "L")
                      }
                      variant="outlined"
                    />
                  </Stack>
                )}
                {sal?.suggestedRange && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    columnGap={0.5}
                    mb={2}
                  >
                    <Typography variant="caption">Suggested : </Typography>
                    <Chip
                      label={
                        Salarycount(
                          formatRange(sal?.suggestedRange).start,
                          "L"
                        ) +
                        " - " +
                        Salarycount(formatRange(sal?.suggestedRange).end, "L")
                      }
                      variant="outlined"
                      color="primary"
                    />
                  </Stack>
                )}
                {sal?.availableRange && (
                  <Stack
                    sx={{
                      width: "100%",
                      "& .MuiSlider-rail": {
                        height: "7px",
                        bgcolor: "#e1e1e1",
                        opacity: "1",
                        border: "0",
                        borderRadius: "27px",
                      },
                      "& .MuiSlider-thumb": {
                        width: "24px",
                        height: "24px",
                        bgcolor: "#fff",
                        border: "1px solid #225290",
                        left: "12px",
                        "::before": {
                          content: '"|||"',
                          fontSize: "9px",
                          textAlign: "center",
                          lineHeight: "22px",
                          fontWeight: "600",
                        },
                      },
                      "& .MuiSlider-valueLabel": {
                        bgcolor: "#2a5798",
                        borderRadius: "30px",
                        p: "0.25rem 0.5rem",
                      },
                    }}
                  >
                    <Slider
                      value={value}
                      color="primary"
                      onChange={(e, value) => {
                        if (
                          Number(sal?.availableRange?.split("#")[0]) !==
                          Number(sal?.availableRange?.split("#")[1])
                        )
                          setValue(value);
                      }}
                      onChangeCommitted={(e, value) => {
                        if (
                          Number(sal?.availableRange?.split("#")[0]) !==
                          Number(sal?.availableRange?.split("#")[1])
                        )
                          handleChange(e, value, "sal");
                      }}
                      min={formatMinMaxRange(sal?.availableRange).start}
                      max={formatMinMaxRange(sal?.availableRange).end}
                      valueLabelFormat={() => Salarycount(value, "L")}
                      valueLabelDisplay={"on"}
                    />
                  </Stack>
                )}
              </>
            ) : (
              ""
            )}
            {check === "npd" ? (
              <>
                <Typography
                  component="h3"
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                    mb: 2,
                    textAlign: "center",
                  }}
                >
                  Maximum Notice Period ({npd?.unit || "Days"})
                </Typography>
                {npd?.suggestedRange && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    columnGap={0.5}
                    mb={2}
                  >
                    <Typography variant="caption">Suggested Range: </Typography>
                    <Chip
                      label={
                        formatRange(npd?.suggestedRange).start +
                        "D" +
                        " - " +
                        formatRange(npd?.suggestedRange).end +
                        "D"
                      }
                      variant="outlined"
                      color="primary"
                    />
                  </Stack>
                )}
                {npd?.dataList && (
                  <RoundBtnList
                    data={npd?.dataList?.toString()?.split(",")}
                    selectedValue={npd?.selectedValue}
                    handleChange={(e, value) => handleChange(e, value, "npd")}
                  />
                )}
              </>
            ) : (
              ""
            )}
            {check === "empType" ? (
              <>
                <Typography
                  component="h3"
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                    mb: 2,
                    textAlign: "center",
                  }}
                >
                  Employment Type
                </Typography>
                {empType?.dataList?.length > 0 && (
                  <RadioBtnList
                    data={empType}
                    handleChange={(e, value) =>
                      handleChange(e, value, "empType")
                    }
                    setShowMorePopup={setShowMorePopup}
                    name="empType"
                    ShowMoreHeader={"Employment Type"}
                  />
                )}
              </>
            ) : (
              ""
            )}
            {check === "edu" ? (
              <>
                <Typography
                  component="span"
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                    mb: 2,
                    textAlign: "center",
                  }}
                >
                  Education Required
                </Typography>
                {edu?.dataList?.length > 0 && (
                  <RadioBtnList
                    data={edu}
                    handleChange={(e, value) => handleChange(e, value, "edu")}
                    setShowMorePopup={setShowMorePopup}
                    name="edu"
                    ShowMoreHeader={"Minimum Education Required"}
                  />
                )}
              </>
            ) : (
              ""
            )}
          </Stack>
        </SwipeableDrawer>
      ) : (
        ""
      )}
    </Stack>
  );
}

export default React.memo(Mobile);
