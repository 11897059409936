const formatKeyword = (str) => {
  if (Boolean(str?.trim() !== "")) {
    return str
      ?.replace(/[^\w\s,()!@#\$&+\-/*]/gi, "")
      ?.replace(/&/g, "-")
      ?.replace(/\//g, "-")
      ?.replace(/[,\s]+/g, "-")
      ?.toString()
      ?.toLowerCase();
  } else {
    return "";
  }
};

export default formatKeyword;
