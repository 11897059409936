import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Slider from "@mui/material/Slider";
import dynamic from "next/dynamic";

const RadioBtnList = dynamic(() => import("../RadioBtnListComponent"), {
  ssr: true,
});
const RoundBtnList = dynamic(() => import("../RoundBtnListComponent"), {
  ssr: true,
});

const formatRange = (range) => {
  return {
    start: Number(range?.split("#")[0]),
    end: Number(range?.split("#")[1]),
  };
};

function Desktop({
  location,
  setShowMorePopup,
  handleChange,
  exp,
  sal,
  npd,
  edu,
  empType,
  Salarycount,
  value,
  formatMinMaxRange,
  setValue,
  handleOnFocus
}) {
  return (
    <Stack direction="column" rowGap={0.5} alignItems="start">
      <Typography
        component="h3"
        sx={{
          fontSize: { xs: "13px", sm: "14px", md: "16px" },
          fontWeight: { xs: "400", sm: "700" },
          mb: 1,
        }}
      >
        Location
      </Typography>
      {location?.dataList?.length > 0 && (
        <RadioBtnList
          data={location}
          handleChange={(e, value) => { handleChange(e, value, "location"); handleOnFocus() }}
          setShowMorePopup={setShowMorePopup}
          name="location"
          ShowMoreHeader={"Location"}
        />
      )}
      <Typography
        component="h3"
        sx={{
          fontSize: { xs: "13px", sm: "14px", md: "16px" },
          fontWeight: { xs: "400", sm: "700" },
          mt: 1.5,
          mb: 1,
        }}
      >
        Minimum Experience Required ({exp?.unit})
      </Typography>
      {exp?.suggestedRange && (
        <Stack direction="row" alignItems="center" columnGap={0.2} mb={2}>
          <Typography variant="caption" mr={1}>
            Suggested Range:{" "}
          </Typography>
          <Chip
            label={
              formatRange(exp?.suggestedRange).start +
              "Y" +
              " - " +
              formatRange(exp?.suggestedRange).end +
              "Y"
            }
            variant="outlined"
            color="primary"
            clickable={false}
          />
        </Stack>
      )}
      {exp?.dataList && (
        <RoundBtnList
          data={exp?.dataList?.toString()?.split(",")}
          selectedValue={exp?.selectedValue}
          handleChange={(e, value) => { handleChange(e, value, "exp"); handleOnFocus() }}
        />
      )}
      <Typography
        component="h3"
        sx={{
          mt: 1.5,
          mb: 1,
          fontSize: { xs: "13px", sm: "14px", md: "16px" },
          fontWeight: { xs: "400", sm: "700" },
        }}
      >
        Minimum Acceptable Salary ({sal?.unit})
      </Typography>
      <Typography
        variant="caption"
        sx={{
          display: { xs: "none", sm: "block" },
          mb: 1,
          color: (theme) => theme.palette.dark[900],
        }}
      >
        Drag the slider to select desired value
      </Typography>
      {sal?.availableRange && (
        <Stack direction="row" alignItems="center" columnGap={0.5} mb={0.5}>
          <Typography variant="caption">Available : </Typography>
          <Chip
            label={
              Salarycount(formatRange(sal?.availableRange).start, "L") +
              " - " +
              Salarycount(formatRange(sal?.availableRange).end, "L")
            }
            variant="outlined"
          />
        </Stack>
      )}
      {sal?.suggestedRange && (
        <Stack direction="row" alignItems="center" columnGap={0.5} mb={2}>
          <Typography variant="caption">Suggested : </Typography>
          <Chip
            label={
              Salarycount(formatRange(sal?.suggestedRange).start, "L") +
              " - " +
              Salarycount(formatRange(sal?.suggestedRange).end, "L")
            }
            variant="outlined"
            color="primary"
          />
        </Stack>
      )}
      {sal?.availableRange && (
        <Stack
          sx={{
            mt: 2.5,
            width: "100%",
            p: "0 32px",
            "& .MuiSlider-rail": {
              height: "7px",
              bgcolor: "#e1e1e1",
              opacity: "1",
              border: "0",
              borderRadius: "27px",
            },
            "& .MuiSlider-thumb": {
              width: "24px",
              height: "24px",
              bgcolor: "#fff",
              border: "1px solid #225290",
              left: "12px",
              "::before": {
                content: '"|||"',
                fontSize: "9px",
                textAlign: "center",
                lineHeight: "22px",
                fontWeight: "600",
              },
            },
            "& .MuiSlider-valueLabel": {
              bgcolor: "#2a5798",
              borderRadius: "30px",
              p: "0.25rem 0.5rem",
            },
          }}
        >
          <Slider
            value={value}
            color="primary"
            onChange={(e, value) => {
              if (
                Number(sal?.availableRange?.split("#")[0]) !==
                Number(sal?.availableRange?.split("#")[1])
              )
                setValue(value);
              handleOnFocus()
            }}
            onChangeCommitted={(e, value) => {
              if (
                Number(sal?.availableRange?.split("#")[0]) !==
                Number(sal?.availableRange?.split("#")[1])
              )
                handleChange(e, value, "sal");
              handleOnFocus()
            }}
            min={formatMinMaxRange(sal?.availableRange).start}
            max={formatMinMaxRange(sal?.availableRange).end}
            valueLabelFormat={() => Salarycount(value, "L")}
            valueLabelDisplay={"on"}
          />
        </Stack>
      )}
      <Typography
        component="h3"
        sx={{
          fontSize: { xs: "13px", sm: "14px", md: "16px" },
          fontWeight: { xs: "400", sm: "700" },
          mt: 1.5,
          mb: 1,
        }}
      >
        Maximum Notice Period ({npd?.unit || "Days"})
      </Typography>
      {npd?.suggestedRange && (
        <Stack direction="row" alignItems="center" columnGap={0.5} mb={2}>
          <Typography variant="caption" mr={1}>
            Suggested Range:{" "}
          </Typography>
          <Chip
            label={
              formatRange(npd?.suggestedRange).start +
              "D" +
              " - " +
              formatRange(npd?.suggestedRange).end +
              "D"
            }
            variant="outlined"
            color="primary"
          />
        </Stack>
      )}
      {npd?.dataList && (
        <RoundBtnList
          data={npd?.dataList?.toString()?.split(",")}
          selectedValue={npd?.selectedValue}
          handleChange={(e, value) => { handleChange(e, value, "npd"); handleOnFocus() }}
        />
      )}
      <Typography
        component="h3"
        sx={{
          mt: 1.5,
          mb: 1,
          fontSize: { xs: "13px", sm: "14px", md: "16px" },
          fontWeight: { xs: "400", sm: "700" },
        }}
      >
        Employment Type
      </Typography>
      {empType?.dataList?.length > 0 && (
        <RadioBtnList
          data={empType}
          handleChange={(e, value) => { handleChange(e, value, "empType"); handleOnFocus() }}
          setShowMorePopup={setShowMorePopup}
          name="empType"
          ShowMoreHeader={"Employment Type"}
        />
      )}
      <Typography
        component="span"
        sx={{
          mt: 1.5,
          mb: 1,
          fontSize: { xs: "13px", sm: "14px", md: "16px" },
          fontWeight: { xs: "400", sm: "700" },
        }}
      >
        Education Required
      </Typography>
      {edu?.dataList?.length > 0 && (
        <RadioBtnList
          data={edu}
          handleChange={(e, value) => { handleChange(e, value, "edu"); handleOnFocus() }}
          setShowMorePopup={setShowMorePopup}
          name="edu"
          ShowMoreHeader={"Minimum Education Required"}
        />
      )}
    </Stack>
  );
}

export default React.memo(Desktop);
