/* eslint-disable import/no-anonymous-default-export */
export default {
  getSubscriptionPack: "/get-subscription-pack",
  getSubscriptionPricingActivity: "/get-subscription-pricing-activity",
  login: "/auth/login",
  profilecompletion: "/auth/check-pref-resume-exist",
  mobileOtp: "/auth/mobile_otp_verify",
  emailOtp: "/auth/email_otp_verify",
  resendOtp: "/auth/resend_otp",
  employerLogin: "/employer/auth/logIn",
  verifyEmpOtp: "/employer/auth/verifyOTP",
  empResendOtp: "/employer/auth/sendOTP",
  resetPasswordRequest: "/employer/auth/resetPasswordRequest",
  saveResetPassword: "/employer/auth/saveResetPassword",
  verifyResetPasswordOTP: "/employer/auth/verifyResetPasswordOTP",
  blog: "/blogs",
  getAlljob: "/get-all-job-titles",
  manualRegisOtp: "/employer/manualRegs/send-employer-regs-otp",
  verifyMAnualRegisOtp: "/employer/manualRegs/verifyOTP",

  toGetJobType: "/search/employee/get-job-types",
  searchListUrl: "/common/getJobTitleSuggestion",
  parentTypeUrl: "/search/employee/get-parent-types",
  searchSugessionListUrl: "/search/employee/job-title-list",

  seekerSignup: "/auth/signup",
  employerSignup: "/employer/auth/signUp",
  seekerEmailVerify: "/auth/email_otp_verify",
  applyjob: "/applyToJobPost",
  savejob: "/saveJobPost",
  seekerMobileVerify: "/auth/signup/mobile_otp_verify",
  employerCheckDetail: "/employer/auth/check_signup_detail",
  expList: "/common/relevantExperience",
  seekerSignupMobile: "/v2/auth/signup",

  checkGroupNameApi: "/employer/institution/check_group_name",
  checkInstitutionNameApi: "/employer/institution/check_institute_name",
  fetchSuggesstionInstitute: "/employer/institution/all_institution_suggestion",
  getInstituteHeirachyApi: "/employer/common/get_institution_heirachy",
  addInstituteApi: "/employer/institution/add_institution_details",
  employeeJobSearchNew: "/DEV_employeeJobSearch",
  jobCardDetailsNew: "/full-job-details",
  parenttype: "/search/employee/get-parent-types",
  jobtypeList: "/search/employee/get-job-types",
  suggestedJobtitlelist: "/search/employee/job-title-list",
  LogoList: "/common/get-logo-listing",

  dashboardStats: "/landing-stats",
  contactUs: "/contact-us",
  validTokenCheckSeeker: "/auth/seeker/check-valid-token",
  validTokenCheckEmp: "/auth/employer/check-valid-token",
  openJobSearch: "/open-job-search",

  getAllCities: "employer/common/countryCities/101",
  getSearchFooterData: "/seo/search-footer",
  getNavbarJobsData: "/seo/navbar-jobs",

  getAllDesignation: "/all-designations",
  getAllCity: "/all-featured-cities",

  getProfessorJobs: "/get-all-professor-job-titles"
};
